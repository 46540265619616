import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'

// React Icons
import { BsGithub } from 'react-icons/bs'

const EditContainer = styled.div`
  cursor: pointer;
  display: flex-inline;
  align-items: center;
  vertical-align: center;
  flex-direction: row;
  font-size: ${({ theme }) => theme.desktopSizes.small};
  margin-bottom: ${({ theme }) => theme.desktopSizes.small};
`

const Icon = styled(BsGithub)`
  vertical-align: middle;
  margin-right: ${({ theme }) => theme.spacings.xxSmall};
`

const EditLink = styled.a`
  color: ${({ theme }) => theme.colors.color7} !important;
  &:hover {
    color: ${({ theme }) => theme.colors.color1} !important;
    border-bottom: none !important;
  }
`

const EditPage = ({ filePath, ...props }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            github {
              org
              repo
            }
          }
        }
      }
    `,
  )
  const { org, repo } = site?.siteMetadata?.github

  const repoUrl = url =>
    `https://github.com/${org}/${repo}/${url.replace(/^.*\/content/g, `edit/main/content`)}`

  return org === false || repo === false || filePath.length === 0 ? (
    ''
  ) : (
    <EditContainer {...props}>
      <EditLink target="_blank" rel="noreferrer" title="Github" href={repoUrl(filePath)}>
        {<Icon />} Edit this page on Github
      </EditLink>
    </EditContainer>
  )
}

EditPage.propTypes = {
  filePath: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default EditPage
