import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'gatsby'

const PageContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacings.medium};
  padding-right: ${({ theme }) => theme.spacings.medium};
  width: ${({ theme }) => theme.aside.secondary};
  min-width: ${({ theme }) => theme.aside.secondary};
  max-width: ${({ theme }) => theme.aside.secondary};
  display: flex;
  flex-direction: column;
  position: fixed !important;
  right: 0;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: none;
  }
`

const PageHeader = styled.div`
  padding-bottom: ${({ theme }) => theme.spacings.xxSmall};
  border-bottom: 1px solid ${({ theme }) => theme.colors.color5};
`

const TocSubhead = styled.div`
  padding-top: ${({ theme }) => theme.spacings.xxSmall};
`

const TocLink = styled(Link)`
  color: ${({ theme }) => theme.colors.color2} !important;
  text-decoration: none;
  font-size: ${({ theme }) => theme.desktopSizes.small};
  &:hover {
    color: ${({ theme }) => theme.colors.color1} !important;
    transition: ${({ theme }) => theme.animations.trans3};
    text-decoration: underline;
  }
`

const ChildContents = styled.div`
  padding-left: ${({ theme }) => theme.spacings.xSmall};
  word-wrap: break-word;
`

const ChildItem = styled.div`
  margin-bottom: ${({ theme }) => theme.spacings.xxSmall};
  word-wrap: break-word;
`

const ThisPage = ({ toc }) => {
  return (
    <PageContainer>
      {toc === 0 ? (
        ''
      ) : (
        <>
          <PageHeader>ON THIS PAGE</PageHeader>
          <>
            {toc.items.map((item, key) => {
              const { items, title, url } = item
              return (
                <React.Fragment key={key}>
                  <TocSubhead>
                    <TocLink to={url}>{title}</TocLink>
                  </TocSubhead>
                  {items && items.length > 0 && (
                    <>
                      <ChildContents>
                        {items.map((cHead, key) => {
                          const { title, url } = cHead
                          return (
                            <React.Fragment key={key}>
                              <ChildItem>
                                <TocLink to={`${url.replace('/#', '#')}`}>{title}</TocLink>
                              </ChildItem>
                            </React.Fragment>
                          )
                        })}
                      </ChildContents>
                    </>
                  )}
                </React.Fragment>
              )
            })}
          </>
        </>
      )}
    </PageContainer>
  )
}

ThisPage.propTypes = {
  toc: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
}

export default ThisPage
