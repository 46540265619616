import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import styled, { useTheme } from 'styled-components'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'

// Components
import Layout from '../components/Layout'
import SideNav from '../components/SideNav'
import ThisPage from '../components/ThisPage'
import EditPage from '../components/EditPage'
import Pagination from '../components/Pagination/'
import LastModTime from '../components/LastModTime'

// Context
import { LayoutContext } from '../context/LayoutProvider'

const DocContainer = styled.main`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  height: 100%;

  a {
    color: ${({ theme }) => theme.colors.color1};
    text-decoration: none;
  }
  a:hover {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.color2};
    border-bottom: 1px solid ${({ theme }) => theme.colors.color1};
  }
`

const LeftNav = styled.div`
  margin-top: ${({ theme }) => theme.spacings.medium};
`

const LeftNavHeader = styled.h4`
  font-weight: 300;
  text-align: center;
  letter-spacing: 0.01em;
  color: ${({ theme }) => theme.colors.color8};
  margin-top: ${({ theme }) => theme.spacings.medium};
  margin-bottom: ${({ theme }) => theme.spacings.small};
  display: none;
`

const DocContent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacings.medium};
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    margin-right: ${({ theme }) => theme.aside.secondary} !important;
  }

  h2,
  h3 {
    /* padding-top: ${({ headerHeight, theme }) =>
      `calc(${theme.spacings.small} + ${headerHeight}px)`} !important;
    margin-top: ${({ headerHeight, theme }) =>
      `calc(-${theme.spacings.small} - ${headerHeight}px)`} !important; */
    margin-top: ${({ theme }) => theme.spacings.xSmall};
    scroll-margin-top: ${({ headerHeight, theme }) =>
      `calc(${theme.spacings.small} + ${headerHeight}px)`} !important;
  }
`

const Mdx = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;

  h2 a:hover,
  h3 a:hover {
    border-bottom: none !important;
  }
  .head-anchor {
    visibility: hidden;
    margin-right: ${({ theme }) => theme.spacings.medium};
    height: 100%;
    fill: ${({ theme }) => theme.colors.color1};
  }
  h2:hover .head-anchor,
  h3:hover .head-anchor {
    visibility: visible;
    transition: ${({ theme }) => theme.animations.trans3};
  }

  b,
  strong {
    font-weight: 600 !important;
  }
  li,
  li > p {
    margin-bottom: ${({ theme }) => theme.spacings.xSmall};
  }
`

const Docs = ({ location, data }) => {
  const theme = useTheme()
  const { headerHeight } = useContext(LayoutContext)
  const { title, slug, description, keywords } = data?.mdx?.frontmatter
  const toc = Object.keys(data?.mdx?.tableOfContents).length === 0 ? 0 : data?.mdx?.tableOfContents
  const pageUrl = data?.mdx?.fileAbsolutePath.length === 0 ? 0 : data?.mdx?.fileAbsolutePath
  const modTime = data?.mdx?.parent?.modifiedTime

  return (
    <>
      <Layout seo={{ title, slug, description, keywords }} location={location}>
        <DocContainer>
          <LeftNav className="d-none d-sm-none d-md-none d-lg-block">
            <LeftNavHeader>Documentation</LeftNavHeader>
            <SideNav />
          </LeftNav>
          <DocContent headerHeight={headerHeight}>
            <LastModTime data={modTime} />
            <h1>{title}</h1>

            <EditPage filePath={pageUrl} />
            <Mdx>
              <MDXRenderer>{data.mdx.body}</MDXRenderer>
            </Mdx>
            <EditPage style={{ marginTop: theme.spacings.medium }} filePath={pageUrl} />
            <Pagination slug={slug} />
          </DocContent>
          <ThisPage toc={toc} />
        </DocContainer>
      </Layout>
    </>
  )
}

Docs.propTypes = {
  location: PropTypes.object.isRequired,
}

export const docsQuery = graphql`
  query SingleDocsQuery($id: String!) {
    mdx(id: { eq: $id }) {
      body
      frontmatter {
        title
        slug
        keywords
        date
      }
      fileAbsolutePath
      tableOfContents
      timeToRead
      parent {
        ... on File {
          id
          name
          modifiedTime(formatString: "MMMM DD, YYYY")
        }
      }
    }
  }
`

export default Docs
