import React from 'react'
import PropTypes from 'prop-types'
import { Col } from 'react-bootstrap'
import { Link } from 'gatsby'
import styled from 'styled-components'

const ColContainer = styled(Col)`
  margin-top: ${({ theme }) => theme.spacings.small};
  margin-bottom: ${({ theme }) => theme.spacings.small};
  .hidden {
    visibility: hidden;
    width: 0;
  }
`

const PagLink = styled(Link)`
  width: 100%;
  display: flex;
  flex-direction: column;

  &:hover {
    border-bottom: none !important;
  }
`

const PagContent = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.color5};
  border-radius: ${({ theme }) => theme.spacings.xSmall};
  width: 100%;
  padding: ${({ theme }) => theme.spacings.small};
`

const PagHeader = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.color8} !important;
  margin-bottom: ${({ theme }) => theme.spacings.xSmall};
  font-size: ${({ theme }) => theme.desktopSizes.small};
`

const PagTitle = styled.div`
  display: flex;
`

const PagCol = ({ obj, right }) => {
  return (
    <>
      {obj === null ? (
        <ColContainer className="hidden" xs={12} sm={12} md={6} />
      ) : (
        <ColContainer xs={12} sm={12} md={6}>
          <PagLink to={`/docs${obj.link}`}>
            <PagContent>
              <PagHeader style={{ ...(right ? { justifyContent: 'flex-end' } : '') }}>
                {right ? 'Next' : 'Previous'}
              </PagHeader>
              <PagTitle style={{ ...(right ? { justifyContent: 'flex-end' } : '') }}>
                {obj.title}
              </PagTitle>
            </PagContent>
          </PagLink>
        </ColContainer>
      )}
    </>
  )
}

PagCol.defaultTypes = {
  right: false,
}

PagCol.propTypes = {
  obj: PropTypes.object,
  right: PropTypes.bool,
}

export default PagCol
