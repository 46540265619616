import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const ModContainer = styled.main`
  font-size: ${({ theme }) => theme.desktopSizes.small};
  color: ${({ theme }) => theme.colors.color7};
  margin-bottom: ${({ theme }) => theme.spacings.xSmall};
`

const LastModTime = ({ data }) => {
  return <ModContainer>File last modified on {data}</ModContainer>
}

LastModTime.propTypes = {
  data: PropTypes.string.isRequired,
}

export default LastModTime
