const handlePageGen = (slug, data) => {
  let start = 0
  const newObj = data.flatMap(({ items }) => items.map(item => ({ id: start++, ...item })))

  const current = Object.values(newObj).find(item => item.link === `/${slug}`)
  const prev =
    current.id === 0 ? null : Object.values(newObj).find(item => item.id === current.id - 1)
  const next =
    current.id === newObj.length - 1
      ? null
      : Object.values(newObj).find(item => item.id === current.id + 1)
  return { prev, next }
}

export default handlePageGen
