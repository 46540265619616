import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import { Row } from 'react-bootstrap'

// Components
import PagCol from './PagCol'

// Utils
import handlePageGen from '../../utils/handlePageGen'

const PagContainer = styled.div`
  width: 100%;
  margin-top: ${({ theme }) => theme.spacings.xxSmall};
`

const Pagination = ({ slug }) => {
  const data = useStaticQuery(
    graphql`
      {
        allDocLinksYaml {
          nodes {
            items {
              link
              title
            }
          }
        }
      }
    `,
  )

  const { prev, next } = handlePageGen(slug, data?.allDocLinksYaml?.nodes)

  return (
    <PagContainer>
      <Row>
        <PagCol obj={prev} />
        <PagCol obj={next} right={true} />
      </Row>
    </PagContainer>
  )
}

Pagination.propTypes = {
  slug: PropTypes.string,
}

export default Pagination
